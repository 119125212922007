/* Commerce Utilities for connecting to Magento GraphQL & Rest Services */
/* eslint-disable-next-line no-unused-vars */
const CapVerifyUtils = (() => {

	const SET_VALID_CAP =  new Set(['00135', '00136', '00137', '00138', '00139', '00141', '00142', '00143', '00144', '00145', '00146', '00147', '00148', '00149', '00151', '00152', '00153', '00154', '00155', '00156', '00157', '00158', '00159', '00161', '00162', '00163', '00164', '00165', '00166', '00167', '00168', '00169', '00171', '00172', '00173', '00174', '00175', '00176', '00177', '00178', '00179', '00181', '00182', '00183', '00184', '00185', '00186', '00187', '00188', '00189', '00191', '00192', '00193', '00195', '00196', '00197', '00198', '00199', '20121', '20122', '20123', '20124', '20125', '20126', '20127', '20128', '20129', '20130', '20131', '20132', '20133', '20134', '20135', '20136', '20137', '20138', '20139', '20140', '20141', '20142', '20143', '20144', '20145', '20146', '20147', '20148', '20149', '20150', '20151', '20152', '20153', '20154', '20155', '20156', '20157', '20158', '20159', '20160', '20161', '20162', '80121', '80122', '80123', '80124', '80125', '80126', '80127', '80128', '80129', '80130', '80131', '80132', '80133', '80134', '80135', '80136', '80137', '80138', '80139', '80140', '80141', '80142', '80143', '80144', '80145', '80146', '80147', '95121', '95122', '95123', '95124', '95125', '95126', '95127', '95128', '95129', '95130', '95131']);

	// private method(s)
	const _constructor = () => {};

	const _checkCapValidty = (cap) => {
		return SET_VALID_CAP.has(cap);
	}


	_constructor();

	// output/public
	return {
		checkCapValidty: _checkCapValidty,
	};
})();
