

const QRCodeGenerator = (() => {

	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};


	const _createQRCode = (text,element) =>{
		const scriptElement = document.createElement('script');
		scriptElement.src = 'https://cdn.jsdelivr.net/gh/davidshimjs/qrcodejs/qrcode.min.js';

		document.head.appendChild(scriptElement);

		scriptElement.onload = function () {
			const qrcode = new QRCode(element, {
				text: text,
				width: 128,
				height: 128
			});
		};
	}

	_constructor();

	// output/public
	return {
		createQRCode: _createQRCode
	};

})();