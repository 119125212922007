

const StoreLocatorUtils = (() => {
	


	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	const _getFilterDictionary = () => {		//unico filtro
		const filter_dictionary={
			"":"activity_type",
			"":"activity_type2",
			"":"activity_type1",
			"":"activity_type5",
			"":"activity_type4",
			"":"activity_type3",
			"glo ZONE": "store_type",
			"JUST STICKS": "store_type",
			"glo DEVICE AND STICKS": "store_type"
		}
		return filter_dictionary;
	}
	

	const _getActivityDictionary = () => {
		const activity_dictionary={
			"":"activity_type",
			"":"activity_type2",
			"":"activity_type3",
			"":"activity_type4"
		}
		return activity_dictionary;
	}

	const _getStoreDictionary = () => {
		const store_dictionary={
			"STIKOVI ZA glo™": "JUST STICKS",
            "glo™ ZONA":"glo ZONE",
            "glo™ UREĐAJ":"glo DEVICE AND STICKS"
		}
		return store_dictionary;
	}

	const _loadStores = async (componentObject) => {
		const jsonPath = componentObject.productImagePath;
	
		const request = await fetch(jsonPath)
			.then((response) => response.json())
			.then(async (formResponse) => {
				if (formResponse) {
					const { steps } = formResponse;
	
					if (steps && steps.length > 0) {
						// Loop steps
						await Promise.all(
							steps.map(async (step) => {
								const { formBlocks } = step;
	
								if (formBlocks && formBlocks.length > 0) {
									// Loop Rows
									await Promise.all(
										formBlocks.map(async (block, index) => {
											const { $ref } = block;
	
											if ($ref) {
												await _loadBlockJSON($ref).then(
													(blockResponse) => {
														formBlocks[
															index
														] = blockResponse;
													}
												);
											}
										})
									);
								}
							})
						);
					}
	
					componentObject.stores = formResponse;
				}
	
				return componentObject;
			})
			.catch((error) => {
				/* eslint-disable-next-line no-console */
				console.log('Request failed: ', error);
			});
	
		return componentObject.stores;
	};


	_constructor();

	// output/public
	return {
		loadStores: _loadStores,
		getActivityDictionary: _getActivityDictionary,
		getStoreDictionary: _getStoreDictionary,
		getFilterDictionary: _getFilterDictionary
	};
})();