const commerceInterfacesLoaded = new CustomEvent('commerce-interfaces-loaded');

/* eslint-disable-next-line no-unused-vars */
const requestHeader = {
	'Content-Type': 'application/json',
	store:
		typeof store !== 'undefined' && store !== '' ? store : 'glo_it_it_it',
};

/* eslint-disable-next-line no-unused-vars */
const productPropertiesInterface = `
	uid
	id
	sku
	name
	rating_summary
	is_wallet_product
	__typename
	stock_status
	short_description {
    	html
  	}
	description {
     	html
   	}
	psn_is_personalisable
	reviews {
		total_count
	}
	image {
		url
		label
	}
	small_image {
		url
		label
	}
	media_gallery {
		disabled
		label
		url
		... on ProductVideo { 
			video_content { 
				video_url 
			} 
		}
	}
	price_range {
		minimum_price {
			regular_price {
				value
				currency
			}
			final_price {
				value
				currency
			}
			discount {
				amount_off
				percent_off
			}
		}
		maximum_price {
			regular_price {
				value
				currency
			}
			final_price {
				value
				currency
			}
		}
	}
`;

/* eslint-disable-next-line no-unused-vars */
const minimalProductPropertiesInterface = `
	uid
	sku
	name
	__typename
	stock_status
`;

// Custom Properties
const customProductPropertiesInterface = `
	supplementary_block_1
	background_image {
		url
		label
	}
	categories {
		name
		id
		uid
	}
	url_key
	display_name
	lowest30days_price
`;

/* eslint-disable-next-line no-unused-vars */
const categoryListInterface = `
	uid
	name
	products {
		items {
			${minimalProductPropertiesInterface}
			${customProductPropertiesInterface}
		}
	}
	product_count
	children {
		uid
		name
		products {
			items {
				${minimalProductPropertiesInterface}
				${customProductPropertiesInterface}
			}
		}
	}
`;

/* eslint-disable-next-line no-unused-vars */
const configurableInterface = `
	... on ConfigurableProduct {
		configurable_options {
			uid
			attribute_uid
			label
			position
			use_default
			attribute_code
			values {
				uid
				label
				store_label
				default_label
				use_default_value
				swatch_data {
					value
				}
			}
		}
		variants {
			product {
				${customProductPropertiesInterface}
				${productPropertiesInterface}
				categories {
					uid
				}
				psn_front_image {
					url
				}
				psn_background_image {
					url
				}
			}
			attributes {
				label
				code
				uid
			}
		}
	}
`;

/* eslint-disable-next-line no-unused-vars */
const bundleInterface = `
	... on BundleProduct {
		price_view
		dynamic_price
		dynamic_sku
		ship_bundle_items
		dynamic_weight
		items {
			option_id
			uid
			title
			required
			type
			position
			sku
			options {
				id
				uid
				label
				quantity
				position
				is_default
				price
				price_type
				can_change_quantity
				product {
					${customProductPropertiesInterface}
					${productPropertiesInterface}
				}
			}
		}
	}
`;

/* eslint-disable-next-line no-unused-vars */
const relatedProductsInterface = `
	related_products {
		${productPropertiesInterface}
	}
`;

window.dispatchEvent(commerceInterfacesLoaded);
