const DataLayerUtils = (() => {

    const isApp = () => {
        return window.location.href.indexOf('/app/') > -1;
    };
	
    const isAndroidApp = () => {
        return isApp() && window.Native;
    };

    const isIOSApp = () => {
        return isApp() && !isAndroidApp();
    };

    const trackMissionEnd = (mission) => {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'MissionEnd'
            });
            dataLayer.push({
                event: `${mission}End`
            });

            if (isAndroidApp()) {
                dataLayer.push({
                    event: 'MissionEndAndroid'
                });
                dataLayer.push({
                    event: `${mission}EndAndroid`
                });
            } else if (isIOSApp()) {
                dataLayer.push({
                    event: 'MissionEndIOS'
                });
                dataLayer.push({
                    event: `${mission}EndIOS`
                });
            }        
        }
    };

    const trackMissionStart = (mission) => {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'MissionStart'
            });
            dataLayer.push({
                event: `${mission}Start`
            });

            if (isAndroidApp()) {
                dataLayer.push({
                    event: 'MissionStartAndroid'
                });
                dataLayer.push({
                    event: `${mission}StartAndroid`
                });
            } else if (isIOSApp()) {
                dataLayer.push({
                    event: 'MissionStartIOS'
                });
                dataLayer.push({
                    event: `${mission}StartIOS`
                });
            }        
        }
    };

    const trackOrderCompleted = () => {
        if (isApp() && typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'OrderComplete'
            });

            if (isAndroidApp()) {
                dataLayer.push({
                    event: 'OrderCompleteAndroid'
                });
            } else if (isIOSApp()) {
                dataLayer.push({
                    event: 'OrderCompleteIOS'
                });
            }        
        }
    }

    const trackGenericEvent = (tag) => {
        if( typeof dataLayer !== 'undefined'){
            if(isApp()){

                if (isAndroidApp()) {
                    dataLayer.push({
                        event: `${tag}Android`
                    });
                } else if (isIOSApp()) {
                    dataLayer.push({
                        event: `${tag}IOS`
                    });
                }        
            }else{
                dataLayer.push({
                    event: `${tag}`
                });
            }
        }
    };

	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	_constructor();

	// output/public
	return {
		isApp,
        isAndroidApp,
        isIOSApp,
        trackMissionStart,
        trackMissionEnd,
        trackOrderCompleted,
        trackGenericEvent
	};

})();