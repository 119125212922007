// ------------------ config (static)
/* eslint-disable-next-line no-unused-vars */
const DateFormatter = (() => {
	// private var(s)
	const _formatTimestamp = (timestamp) =>{
    		const date = new Date(timestamp);
    		const day = String(date.getDate()).padStart(2, '0');
    		const month = String(date.getMonth() + 1).padStart(2, '0');
    		const year = date.getFullYear();
    		const hours = String(date.getHours()).padStart(2, '0');
    		const minutes = String(date.getMinutes()).padStart(2, '0');
    	
    		return `${day}-${month}-${year}, ${hours}:${minutes}`;
    	}

	// private method(s)
	const _constructor = () => {
		// console.log('Config is running');
	};

	_constructor();

	// output/public
	return {
		formatTimestamp: _formatTimestamp,
	};
})();
